import React from "react";

function Welcome() {
    return (
        <>
            <h1>Welcome to Oyo.lol</h1>
        </>
    )
}

export default Welcome;
