import React, {useRef, useState} from "react";
import ShortenUrlTable from "./ShortenUrlTable";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {PageName, UserProfile} from "../datatype/Core";


interface ShortenerProps {
    profile: UserProfile | null;
    setProfile: (profile: UserProfile | null) => void;
}

function Shortener(props: ShortenerProps) {
    const [originalUrl, setOriginalUrl] = useState('');
    const [shortenedUrl, setShortenedUrl] = useState('');
    const [originalUrlErrorMessage, setOriginalUrlErrorMessage] = useState('');

    function do_shorten() {
        console.log("original url %s", originalUrl)
        if (props.profile != null) {
            fetch(`${process.env.REACT_APP_API_DOMAIN}/shortener/shorten`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ url: originalUrl }),
                credentials: 'include'
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.log("reject", response)
                        return Promise.reject(response)
                    }
                })
                .then(json => {
                    console.log("json:", json);
                    let shortened_path = json['data']['shortened_path']
                    setShortenedUrl(`${process.env.REACT_APP_API_DOMAIN}/${shortened_path}`)
                    setOriginalUrl("")
                    setOriginalUrlErrorMessage("")
                })
                .catch((response) => {
                    console.log("%s %s", response.status, response.statusText);
                    // 3. get error messages, if any
                    response.json().then((json: any) => {
                        console.log(json);
                        if (json['detail'] != null) {
                            console.log(json['detail']);
                            if (Array.isArray(json['detail'])) {
                                setOriginalUrlErrorMessage(`${json['detail'][0]['msg']}`)
                            } else if (json['detail']['error'] != null) {
                                setOriginalUrlErrorMessage(`${json['detail']['error']}`)
                            } else {
                                setOriginalUrlErrorMessage(`${json}`)
                            }
                        }
                    })
                });
        }


    }

    function handleOriginalUrlChange(event: any) {
        setOriginalUrl(event.target.value);
    }

    return (
        <>
            <h1>Welcome to Use Url Shortener!!</h1>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'space-around',
                    p: 1,
                    m: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}
            >
                <TextField
                    id="outlined-required"
                    label="Original Url"
                    value={originalUrl}
                    helperText={originalUrlErrorMessage}
                    onChange= {handleOriginalUrlChange}
                    sx={{width: 350}}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'space-around',
                    p: 1,
                    m: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}
            >
                <Button variant="contained" size="small" onClick={do_shorten}> Shorten </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'space-around',
                    p: 1,
                    m: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}
            >
                <TextField
                    id="filled-read-only-input"
                    label="Shortened Url"
                    value= {shortenedUrl}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="filled"
                    sx={{width: 350}}
                />
            </Box>

            {/*<ShortenUrlTable />*/}
        </>
    )
}

export default Shortener;
