

export interface UserProfile {
    expires_at: string;
    email: string;
    profile_name: string;
    picture?: string;
}

export enum PageName {
    Welcome = "Welcome",
    UrlShortener = "Url Shortener",
}
