import React, {ErrorInfo} from 'react';
import './App.css';
import ResponsiveAppBar from "./ResponsiveAppBar";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Welcome from "./components/Welcome";
import {PageName, UserProfile} from "./datatype/Core";
import Shortener from "./components/Shortener";


function App() {
    const [page, switchPage] = React.useState<PageName>(PageName.Welcome)
    const [profile, setProfile] = React.useState<UserProfile | null>(null);

    function componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    return (
        <HelmetProvider>
            <div className="App">
                <Helmet>
                    <title>Oyo.lol</title>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8658444030688406"
                            crossOrigin="anonymous"></script>
                </Helmet>
                <ResponsiveAppBar profile={profile} setProfile={setProfile} switchPage={switchPage}/>
                {(profile == null || page === PageName.Welcome) ?
                    <Welcome/> : page === PageName.UrlShortener ?
                        <Shortener profile={profile} setProfile={setProfile} /> : <Welcome/>
                }
            </div>
        </HelmetProvider>
    );
}

export default App;
